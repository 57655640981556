import * as React from "react";
import Box from "@mui/material/Box";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const items = [
  { title: "How do I find caregiving for a senior loved one on Herewith?", content: "Herewith is a platform for in-home senior care. You can book background-checked Helpers for as much or as little time as you need, giving you complete control over how much care you’d like to receive and how much you’d like to spend per hour." },
  { title: "How does Herewith help save money on senior care?", content: "With no commitments, hourly minimums, or monthly subscriptions, you're in charge of what you want to spend. Pay as you go just for the care you need." },
  { title: "Are Helpers on the Herewith platform vetted?", content: "All of the Helpers you’ll see on our platform are fully background-checked by Checkr and pass both state and federal checks." },
  { title: "What kind of senior care can Helpers do?", content: "Helpers can fulfill a variety of non-medical home help and senior care needs. Book a Helper for one-off tasks, such as picking up an order at the pharmacy, or hire them longer term if you need part-time or full-time care from a friendly Helper." },
  { title: "Is Herewith a nationwide care service?", content: "Founded in California, we are a growing, nationwide senior care platform. Not seeing Helpers in your area yet? Create a Help Request so we can recruit new Helpers to meet your in-home help needs." },
];

interface CustomIconProps {
  style?: React.CSSProperties;
  fillColorRect?: string;
  fillColorPath?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({ style, fillColorRect = "#EBE9E6", fillColorPath = "#85827D" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect width="32" height="32" rx="16" fill={fillColorRect} />
    <path d="M12.868 15.7406L15.3376 18.6909C15.6822 19.1026 16.3174 19.1026 16.6621 18.6909L19.1317 15.7406C19.5996 15.1816 19.2004 14.333 18.4695 14.333L13.5302 14.333C12.7993 14.333 12.4001 15.1816 12.868 15.7406Z" fill={fillColorPath} />
  </svg>
);

const AccordionItem: React.FC<{
  index: number;
  item: any;
  expanded: number[];
  onToggle: (index: number) => void;
  hoveredIndex: number | null;
  onHover: (index: number) => void;
  onLeave: () => void;
}> = ({ index, item, expanded, onToggle, hoveredIndex, onHover, onLeave }) => (
  <div style={{  borderRadius: "16px", overflow: "hidden" }}>
    <div
      onClick={() => onToggle(index)}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={onLeave}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        padding: "22px ",
        paddingLeft:0,
        backgroundColor: hoveredIndex === index ? "#DBF7FF" : "white",
        borderRadius: "16px",
        transition: "background-color 0.3s ease",
        flexWrap: "wrap", // Allow for wrapping on small screens
      }}
    >
      <h3 style={{ display: "flex", alignItems: "center", fontSize: "18px",textAlign:"left", fontFamily: "DM Sans", color: "#0C3D4C", flex: "1" }}>
        {item.title}
      </h3>
      <CustomIcon
        style={{
          transform: expanded.includes(index) ? "rotate(180deg)" : "rotate(0)",
          transition: "transform 0.3s",
          flex: "0 0 auto", // Prevent icon from shrinking
          width: "30px", // Consistent width
          height: "30px", // Consistent height
        }}
        fillColorRect={hoveredIndex === index ? "#ACE0EF" : "#EBE9E6"}
        fillColorPath={hoveredIndex === index ? "#0C3D4C" : "#85827D"}
      />
    </div>
    
    {expanded.includes(index) && (
      <p style={{ padding: "20px", textAlign: "justify", fontFamily: "DM Sans", color: "#706D67", fontSize: "16px" }}>
        {item.content}
      </p>
    )}
   <div style={{ borderBottom: "1px solid #EBE9E6" }}></div>
  </div>
);

export default function CgFaqsa() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const [expanded, setExpanded] = React.useState<number[]>([]);
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((i) => i !== index)
        : [...prevExpanded, index]
    );
  };

  const onHover = (index: number) => {
    setHoveredIndex(index);
  };

  const onLeave = () => {
    setHoveredIndex(null);
  };

  const curve = require("../assets/graycurve.png");

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        justifyContent: "space-between",
        minHeight: "fit-content",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "100%", backgroundColor: "white" }}>
        <img src={curve} alt="background" style={{ width: "100%", border: "none" }} />
      </Box>
      <Box
        sx={{
          width: "100vw",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          justifyContent: "space-between",
          height: phone ? "fit-content" : "30rem",
          backgroundColor: "#FAF8F5 ",
          paddingTop: "2rem",
          paddingBottom: "2rem",
          border: "none",
        }}
      >
        <Paper
          sx={{
            width: "90%",
            backgroundColor: "white",
            minHeight: "fit-content",
            margin: "0 auto",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: phone || tablet ? "column" : "row",
            alignItems: "flex-start",
            padding: phone
              ? "2rem 1rem"
              : tablet
              ? "2rem "
              : "3rem",
            justifyContent: "space-between",
            textAlign:"left",
          }}
        >
          <h2
            style={{
              display: phone || tablet ? "none" : "block",
              textAlign: "left",
              fontSize: "36px",
            }}
          >
            Learn about <br />
            in-home care <br /> on Herewith
          </h2>
          <h2
            style={{
              fontSize: "24px",
              display: phone ? "block" : "none",
              textAlign: "center",
              width: "100%",
              marginBottom: phone ? "1.25rem" : 0,
            }}
          >
            Learn about <br />
            in-home care on Herewith
          </h2>
          <h2
            style={{
              fontSize: "28px",
              display: phone ? "none" : tablet ? "block" : "none",
              textAlign: "left",
              width: "100%",
              marginBottom: "24px",
            }}
          >
            Learn about
            in-home care on Herewith
          </h2>

          <Box
            sx={{
              width: phone || tablet ? "100%" : "60%",
              height: "100%",
            }}
          >
            {items.map((item, index) => (
              <AccordionItem
                key={index}
                index={index}
                item={item}
                expanded={expanded}
                onToggle={handleToggle}
                hoveredIndex={hoveredIndex}
                onHover={onHover}
                onLeave={onLeave}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
