import React from "react";
import icon3 from "../../assets/icons/WheelchairHelp-1x1.jpeg";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const RightContent: React.FC = () => {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const mobile = useMediaQuery(newBp.breakpoints.down("sm"));

  return (
    <img
      src={icon3}
      alt="Helper assisting senior with daily tasks"
      style={{
        width: "100%",
        height:"auto",
        borderRadius: "32px",
        objectFit: "cover", // Ensures the image covers the container without distortion
        aspectRatio: mobile ? "4 / 3" : "auto", // Apply 4:3 aspect ratio for mobile
      }}
    />
  );
};

export default RightContent;
