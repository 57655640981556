import React from "react";
import Box from "@mui/material/Box";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ResponsiveCardProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  border: boolean;
  Imageup: boolean;
}

const ResponsiveCard: React.FC<ResponsiveCardProps> = ({ leftContent, rightContent, border, Imageup }) => {
  // Custom Breakpoints
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: phone ? (Imageup ? "column-reverse" : "column") : "row",
          justifyContent: "center",
          alignItems: "stretch",
          width: "100%",
          borderTop: border ? "1px solid #EBE9E6" : "none",
          borderBottom: border ? "1px solid #EBE9E6" : "none",
          overflow: "hidden",
          padding: phone
            ? "32px 0"
            : tablet
            ? "48px 32px"
            : "4rem", // Fallback for other sizes (like mobile)
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: phone ? "center" : "flex-start",
            paddingLeft:phone?"16px":tablet?"0": "4rem",
            paddingRight: phone?"16px":tablet?"2rem": "4rem",
            paddingTop:phone?"0":tablet?"0": "0",

            textAlign: phone ? "center" : "left",
          }}
        >
          {leftContent}
        </Box>

        {/* Right Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX:phone? "1rem":"",
            minHeight: "300px",
          }}
        >
          {rightContent}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ResponsiveCard;
