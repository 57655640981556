

const EllipseRectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <ellipse
      cx="8"
      cy="4.00033"
      rx="3.33333"
      ry="3.33333"
      fill="#1C1B1A"
    />
    <path
      opacity="0.5"
      d="M8 8C4.86884 8 2.19603 9.96237 1.14433 12.7243C0.620248 14.1007 1.86056 15.3333 3.33332 15.3333H12.6667C14.1394 15.3333 15.3797 14.1007 14.8556 12.7243C13.8039 9.96237 11.1311 8 8 8Z"
      fill="#1C1B1A"
    />
  </svg>
);

export default EllipseRectIcon;
