import * as React from "react";

import IconRepeater from "./IconRepeater.tsx";
import BookTrustedHelpers from "./BookTrustedHelpers.tsx";
import WeCanHelp from "./WeCanHelp.tsx";
import FastBooking from "./FastBooking.tsx";
import TrackHelpRequests from "./TrackHelpRequests.tsx";
import Subscribe from "../../components/Subscribe.tsx";
import { Box } from "@mui/material";
import Partners from "../../components/Partners.tsx";
import RHeader from "../../components/ResponsiveHeaderL.tsx";
import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
import BackToTopButton from "../../components/BackToTopButton.tsx";
import { Helmet } from "react-helmet";
import NewTestimonials from "./NewTestimonialsL.tsx";

interface LandingProps {}

const Landing: React.FC<LandingProps> = () => {  
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Book a Helper today! Get affordable, background-checked help for seniors—choose one-time or ongoing help with everyday tasks. No commitments, no subscriptions."
        />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FAF8F5",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        <Navbar color="#706D67" />
        <RHeader headerImage="" headerTextBeforeSpan="Find " headerTextSpan="everyday help" headerTextAfterSpan=" at home for seniors" paragraphText="" textColor="" backgroundColor="#ACE1EF" buttonText="" secondaryBackgroundColor="#D8F4FC" />
        <IconRepeater />
        <BookTrustedHelpers />
        <Partners />
        <WeCanHelp />
        <FastBooking />
        <TrackHelpRequests />
        <NewTestimonials disappear={false} />
        <Subscribe />
        <Footer />
      </Box>
    </>
  );
};

export default Landing;
