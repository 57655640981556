import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Card, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import icon1 from "../../assets/Home/SVG/heart.svg"
import icon2 from "../../assets/Home/SVG/user.svg"
import icon3 from "../../assets/Home/SVG/clock.svg"
import icon4 from "../../assets/Home/SVG/userbutdiffcolor.svg"
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SeniorCareServices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])

  return (
    <Box
      sx={{
        width: "100%",
        padding:isMobile? "2rem":isTablet? "2rem 3rem":"4rem 3rem",
        // paddingTop:"4rem",
        textAlign: "center",
        borderTop: "1px solid #EBE9E6",
      }}
    >
      <h2
        style={{
          marginBottom: "2rem",
          color: "#115C72",
          fontSize: isMobile? "24px": isTablet?"28px":"36px",
        }}
      >
        Our senior care services{" "}
        <span style={{ fontStyle: "italic", color: "#C97B99" }}>at a glance</span>
      </h2>

      <Grid container spacing={4} justifyContent="center">
        {/* Card 1 */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              backgroundColor: "#FAF8F5",
              borderRadius: "24px",
              padding:isMobile?"24px": "2rem",
              display: "flex",
              flexDirection: isTablet || isMobile ? "row" : "column",
              alignItems: isTablet || isMobile ? "center" : "flex-start",
              gap: "24px",
              textAlign: "left",
              width: "100%",
            }}
          >

              <img src={icon1} alt="Item Icon" style={{ width: "32px", height: "32px" }} />
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    xs: "24px", // Mobile devices
                    sm: "32px", // Tablets
                    md: "48px", // Default for larger screens
                  },            
                }}
              >
                <p className="card-text" style={{fontSize:isMobile?"20px":isTablet?"24px":"28px",color: "#02353c"}} >1000<span style={{ color: "#C97B99"}}>+</span></p>
              </Typography>
              <Typography
                variant="inherit"
                sx={{ color: "#0C3D4C", fontSize: "18px" }}
              >
                <p style={{fontFamily:"DM Sans"}}>
                Hours of help fulfilled on Herewith
                </p>
              </Typography>
            </Box>
          </Card>
        </Grid>

        {/* Repeat for other cards */}
        {/* Card 2 */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
       <Card
 sx={{
  backgroundColor: "#FAF8F5",
  borderRadius: "24px",
  padding:isMobile?"24px": "2rem",
  display: "flex",
  flexDirection: isTablet || isMobile ? "row" : "column",
  alignItems: isTablet || isMobile ? "center" : "flex-start",
  gap: "24px",
  textAlign: "left",
  width: "100%",
}}
>
  <img
    src={icon2} // Icon image for the card
    alt="Item Icon"
    style={{ width: "32px", height: "32px" }} // Icon size
  />
  <Box>
    <Typography
       variant="h3"
       sx={{
         fontSize: {
           xs: "24px", // Mobile devices
           sm: "32px", // Tablets
           md: "48px", // Default for larger screens
         },            
       }}
    >
      <p
        className="card-text"
        style={{
          fontSize:isMobile?"20px":isTablet?"24px":"28px", // Font size for mobile/tablet and larger devices
          color: "#0C3D4C", // Text color for the main number
          margin: 0, // Remove margin from <p> to avoid extra space
        }}
      >
        3000
        <span
          style={{
            color: "#614B9E", // Color for the "+" symbol
          }}
        >
          +
        </span>
      </p>
    </Typography>
    <Typography
      variant="body1" // Body text style
      sx={{
        color: "#0C3D4C", // Text color for the description
        fontSize: "18px", // Font size for the description
      }}
    >
      <p
        style={{
          fontFamily: "DM Sans", // Font family for the description
          margin: 0, // Remove margin from <p> to avoid extra space
        }}
      >
        Active, background-checked Helpers
      </p>
    </Typography>
  </Box>
</Card>

        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
        <Card
 sx={{
  backgroundColor: "#FAF8F5",
  borderRadius: "24px",
  padding:isMobile?"24px": "2rem",
  display: "flex",
  flexDirection: isTablet || isMobile ? "row" : "column",
  alignItems: isTablet || isMobile ? "center" : "flex-start",
  gap: "24px",
  textAlign: "left",
  width: "100%",
}}
>

    <img
      src={icon3} // Icon image
      alt="Item Icon"
      style={{ width: "32px", height: "32px" }} // Icon size
    />
  <Box >
    <Typography
      variant="h3"
      sx={{
        fontSize: {
          xs: "24px", // Mobile devices
          sm: "32px", // Tablets
          md: "48px", // Default for larger screens
        },            
      }}
    >
      <p
        className="card-text"
        style={{
          fontSize:isMobile?"20px":isTablet?"24px":"28px", // Font size for mobile/tablet and larger devices
          color: "#0C3D4C", // Text color for the number
          margin: 0, // Remove margin from <p> to avoid extra space
        }}
      >
        1
        <span
          style={{
            color: "#177A98", // Color for "Day"
          }}
        >
          Day
        </span>
      </p>
    </Typography>

    <Typography
      variant="body1" // Body text style
      sx={{ color: "#0C3D4C", fontSize: "18px" }} // Text styling for the description
    >
      <p
        style={{
          fontFamily: "DM Sans", // Font family for the description
          margin: 0, // Remove margin from <p> to avoid extra space
        }}
      >
        Average time to receive first applicant
      </p>
    </Typography>
  </Box>
</Card>

        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
 sx={{
  backgroundColor: "#FAF8F5",
  borderRadius: "24px",
  padding:isMobile?"24px": "2rem",
  display: "flex",
  flexDirection: isTablet || isMobile ? "row" : "column",
  alignItems: isTablet || isMobile ? "center" : "flex-start",
  gap: "24px",
  textAlign: "left",
  width: "100%",
}}
          >

              <img src={icon4} alt="Item Icon" style={{ width: "32px", height:"32px"  }} />

            <Box >
              <Typography
              >
                <p className="card-text" style={{fontSize:isMobile?"20px":isTablet?"24px":"28px",color:"#0C3D4C"}} >7<span style={{ color: "#4F786B" }}>Applicants</span></p>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#0C3D4C", fontSize: "18px" }}
              >
                <p style={{fontFamily:"DM Sans"}}>
                Average no. of applicants for a request
                </p>
              </Typography>
            </Box>
          </Card>
        </Grid>

      </Grid>

      <Box sx={{ margin: "2rem 0" }}>
      <Link to={`https://app.herewith.com/sign-up${utm}`}>
<Button
  variant="contained"
  endIcon={
    <ArrowForwardIcon
      sx={{
        fontSize: "1.5rem",
        transition: "transform 0.3s ease", // Smooth transition for the movement
      }}
    />
  }
  sx={{
    height: "3.5rem",
    transition: "transform 0.3s ease, background 0.3s ease",
    fontSize: "16px", // Set font size to 16px
    letterSpacing: "0",  // Set line-height to 0 for no spacing between lines
    "&:hover": {
      background: "#E59100",
      transform: "translateX(5px)", // Moves the entire button to the right
      width: "auto", // Optional: Adjust button width if needed
    },
    "&:hover .MuiSvgIcon-root": {
      transform: "translateX(5px)", // Moves the arrow to the right
    },
  }}
>
  Find a Helper near you
</Button>


          </Link>
      </Box>
    </Box>
  );
};

export default SeniorCareServices;
