
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";
import {useWindowSize} from "../utils/Svg"


import headerimg from "../assets/Home/CompressedWebP/BAM.webp";
import { useEffect, useMemo, useState } from "react";

interface HeaderProps {
  headerTextBeforeSpan: string;
  headerTextSpan: string;
  headerTextAfterSpan: string;
  paragraphText: string;
  textColor: string;
  headerImage: string;
  backgroundColor: string;
  secondaryBackgroundColor: string;
  buttonText: string;
}

const RHeader: React.FC<HeaderProps> = ({
  headerTextAfterSpan,
  headerTextSpan,
  headerTextBeforeSpan,
  backgroundColor,
  paragraphText,
}) => {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

 function useImagePreload(imageSrc: string) {
    useEffect(() => {
      const img = new Image();
      img.src = imageSrc;
    }, [imageSrc]);
  }
  
  
  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const windowSize = useWindowSize();
  const customStyles = useMemo(() => {
    if (tablet && windowSize.width >= 600 && windowSize.width <= 990) {
      return { bottom: "-52%", right: "-12%", width: "400px", height: "400px" };
    }
    if (windowSize.width === 1024) {
      return { bottom: "-50%", right: "-5%", width: "420px", height: "420px" }; 
    }
    if (tablet) {
      return { bottom: "-55%", right: "-6%", width: "450px", height: "450px" };
    }
    return { bottom: "-50%", right: "-2%", width: "550px", height: "550px" };
  }, [tablet, windowSize.width]);
  
  const [utm, setUTM] = useState(
    "/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website"
  );

useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, []);

  useImagePreload(headerimg);

  return (
    <div
    style={{
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "32px", // Set bottom margin to 32px
      marginLeft: phone ? "16px" : tablet ? "32px" : "64px", // Adjust left margin based on screen size
      marginRight: phone ? "16px" : tablet ? "32px" : "64px", // Adjust right margin based on screen size
      borderRadius: "2.5rem",
      backgroundColor: backgroundColor,
      overflow: "hidden",
      position: "relative",
    }}
  >
         <div
  style={{
    position: "absolute",
    display:phone?"none":"",
    ...customStyles,
    backgroundColor: "#ACE0EF",
    borderRadius: "50%",
    zIndex: 0,
  }}
></div>
{phone && (
  <div
  style={{
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50%", // Adjust arc height
    backgroundColor: "#ACE0EF",
    borderRadius: "50%",
    zIndex: 1, // Place above the background circle
    transform: "translate(0, 45%)", // Adjust horizontal translation to move more left
  }}
></div>

  )}


      <Box
        sx={{
          display: "flex",
          flexDirection: phone ? "column" : "row",
          alignItems: phone ? "center" : "flex-start",
          height: "auto",
          width: "100%",
          position: "relative", // Ensure text is positioned relative to this container
          zIndex: "1", // Place the content above the icon
        }}
      >
        <Box
          sx={{
            maxWidth: phone ? "92%" : "60%",
            textAlign: phone ? "center" : "left",
            paddingY:phone ? "32px" : tablet ? "40px" : "64px",
            marginLeft: phone ? "0" : tablet ? "40px" : "64px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
              <h1
                style={{
                  fontSize: phone ? "28px" : tablet ? "36px" : "40px",
                  width: phone ? "100%" : tablet ? "150%" : "110%",
                  margin:"0",
                }}
              >
                {headerTextBeforeSpan}{" "}
                <span style={{ fontStyle: "italic", color: "#795ec6" }}>
                Everyday help for seniors:
                </span>
                <br />
                Affordable in-home senior care
              </h1>
              <p
                style={{
                  color: "#115C72",
                  // fontFamily: "DM Sans",
                  paddingRight: phone ? 0 : tablet ? "3rem" : "8rem",
                  lineHeight: "27px",
                }}
              >
                Book professional, on-demand senior home care services for everyday needs on Herewith—offering affordable, flexible care with no hourly minimums or costly subscriptions.
              </p>
           
            <Link to={`https://app.herewith.com/sign-up${utm}`}>
              <Button
                variant="contained"
                endIcon={
                  <ArrowForwardIcon
                    sx={{
                      fontSize: "1.5rem",
                      transition: "transform 0.3s ease", // Smooth transition for the movement
                    }}
                  />
                }
                sx={{
                  height: "3.5rem",
                  marginTop: "2rem",
                  transition: "transform 0.3s ease, background 0.3s ease",
                  fontSize: "16px", // Set font size to 16px
                  letterSpacing: "0",  // Set line-height to 0 for no spacing between lines
                  "&:hover": {
                    background: "#E59100",
                    transform: "translateX(5px)", // Moves the entire button to the right
                    width: "auto", // Optional: Adjust button width if needed
                  },
                  "&:hover .MuiSvgIcon-root": {
                    transform: "translateX(5px)", // Moves the arrow to the right
                  },
                }}
                
              >
                Find a Helper near you
              </Button>
            </Link>
          </Box>
        </Box>  
        <link
          rel="preload"
          href={headerimg}
          as="image"
          type="image/webp"
          media="(max-width: 600px)"
        />

                <img
                  srcSet={`${headerimg} 400w, ${headerimg} 800w, ${headerimg} 1200w`}
                  sizes="(max-width: 600px) 400px, (max-width: 900px) 800px, 1200px"
                  src={headerimg}
                  alt="helper"
                  loading="lazy"
                  style={{
                    alignSelf: phone ? "center" : "flex-end",
                    width: phone ? "85%" : "36vw",
                    maxWidth: "800px",
                    marginLeft: "0px",
                    marginTop: phone ? "2%" : "0",  // Shift the image down for phone
                    marginRight: phone ? "0px" : "-60px",
                    zIndex: "1",
                  }}
                />
      </Box>
    </div>
  );
};

export default RHeader;

