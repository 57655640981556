
import { Box} from "@mui/material";
import RHeader from "../../components/ResponsiveHeader.tsx";
// import stepper from "../../assets/dashstep.png";
// import mod2 from "../../assets/mod2.png";

// interface DashboardProps extends RouteComponentProps {}
import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
import { Helmet } from "react-helmet";
import NewTestimonials from "./NewTestimonials.tsx";

import ResponsiveCard from "../../components/ResponsiveCard.tsx";
import LeftContent from "./LeftContent.tsx";
import RightContent from "./RightContent.tsx";
import AccordianLeft from "./AccordianLeft.tsx";
import AccordianRight from "./AccordianRight.tsx";
import AlignedCard from "../../components/AlignedCard.tsx";
import SeniorCareServices from "./SeniorCareServices.tsx";
import CgFaqsa from "../../components/CgFaqsa.tsx";
import Bookhelp from "./Bookhelp.tsx";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {  

  return (
    <>

   <Helmet>
   <title>Affordable Senior Home Care Services Near You | Herewith</title>
  <meta
    name="description"
    content="Find trusted, flexible, and affordable senior home care services with Herewith. Book professional Helpers for in-home care to support errands, meal preparation, companionship, and more."
  />
  <link rel="preload" href="image.webp" as="image" type="image/webp" />
</Helmet>


      <Box
        sx={{
          // height: "fit-content",
          width: "100%",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >

        <Navbar color="#706D67" sticky={true} backgroundColor="white" />

        {/*<AlertBanner text="Service Update: The Herewith and Helper platforms will undergo scheduled 
        maintenance and will be temporarily unavailable on Thursday, Nov 21, 2024, from approximately 1AM EST–4AM EST.
         We appreciate your understanding while we perform these upgrades."/> */}
        {/* <Header />*/}
        <RHeader headerImage="" headerTextBeforeSpan="" headerTextSpan="" headerTextAfterSpan="" paragraphText="
" textColor="" backgroundColor="#D8F4FC" buttonText="" secondaryBackgroundColor="#D8F4FC" />
        {/* <img
            src={headerimg}
            alt="header image"
            style={{ width: "90%", margin: "1rem auto" }}
          /> */}
          
        {/* <IconRepeater /> */}
        {/* <BookTrustedHelpers /> */}
        <ResponsiveCard leftContent={<LeftContent />} rightContent={<RightContent />} border={true} Imageup={true} />
        {/* <Partners /> */}
        <ResponsiveCard leftContent={<AccordianLeft />} rightContent={<AccordianRight />} border={false} Imageup={false}  />
        {/* <WeCanHelp /> */}
        {/* <FastBooking /> */}
        <AlignedCard/>


        <NewTestimonials disappear={false}/>
        <SeniorCareServices/>
        <CgFaqsa/>
        <Bookhelp/>

        {/* <TrackHelpRequests /> */}
        {/* <Faqs /> */}
        {/* <Subscribe /> */}
        <Footer />
      </Box>
    </>
  );
};

export default Dashboard;


// ┌---------------------------------------------------┐
// |                                                   |
// |                                                   |
// └---------------------------------------------------┘