import * as React from "react";
import Box from "@mui/material/Box";
import { Paper, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowButton from "../../components/ArrowButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import map from "../../assets/Helper Home/CompressedWebP/bookhelpdesktop.png"
import mobileMap from "../../assets/Helper Home/CompressedWebP/bookhelpmobile.jpg"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Bookhelp() {
  const theme = useTheme();
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const curve = require("../../assets/mapbottom.png");
  return (
    <>
    <Helmet>
  <meta
    name="description"
    content="Trusted caregivers supporting seniors at home. Affordable and flexible in-home care services by Herewith, including help with errands, meal preparation, companionship, and more."
  />
</Helmet>

    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${phone ? mobileMap : map})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "fit-content",
        minHeight: tablet ? "30rem" : "38rem",
        backgroundColor: " #FAF8F5",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Paper
        sx={{
          height: phone ? "fit-content" : "50%",
          width: phone ? "90vw" : tablet ? "70%" : "50%",
          borderRadius: "27px",
          textAlign: "center",
          padding: phone ? " 24px" : "2rem",
          margin: "6rem auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            marginBottom:"1rem",
            fontSize: phone? "24px": tablet?"28px":"36px",
            color:"#115C72",  
          }}
        >
          Ready to find the best <br />{" "}
          <span style={{ fontStyle: "italic", color: "#C97B99" }}>
          everyday help
          </span>{" "} for your family?
        </h2>
        <p style={{fontFamily:"DM Sans", color:"#706D67" ,width:phone?"":tablet?"90%":"95%"}}>Book trusted, affordable senior care on Herewith, customized to meet your loved one's needs.</p>
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
       <Button
         variant="contained"
       endIcon={
       <ArrowForwardIcon
       sx={{
        fontSize: "1.5rem",
        transition: "transform 0.3s ease", // Smooth transition for the movement
       }}
        />
       }
       sx={{
        height: "3.5rem",
        marginTop: "1rem",
        transition: "transform 0.3s ease, background 0.3s ease",
        fontSize: "16px", // Set font size to 16px
        letterSpacing: "0",  // Set line-height to 0 for no spacing between lines
        "&:hover": {
          background: "#E59100",
          transform: "translateX(5px)", // Moves the entire button to the right
          width: "auto", // Optional: Adjust button width if needed
        },
        "&:hover .MuiSvgIcon-root": {
          transform: "translateX(5px)", // Moves the arrow to the right
        },
      }}
            >
          Book a Helper today
          </Button>


          </Link>
      </Paper>
      <img
        src={curve}
        alt="f"
        style={{ width: "100%", position: "relative",top:"1px", bottom: 0 }}
      />
    </Box>
    </>
  );
}
