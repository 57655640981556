
import * as React from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import first from "../../assets/Home/CompressedWebP/Newtestimonials1.png"
import second from "../../assets/Home/CompressedWebP/Newtestimonials2.png"
import third from "../../assets/Home/CompressedWebP/Newtestimonials3.png"
import fourth from "../../assets/Home/CompressedWebP/Newtestimonials4.png"
import fifth from "../../assets/Home/CompressedWebP/Newtestimonials5.png"
import sixth from "../../assets/Home/CompressedWebP/Newtestimonials6.png"

interface TestimonialProps {
  disappear?: boolean;
}

const NewTestimonials: React.FC<TestimonialProps> = ({disappear = true}) => {


  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  
  const leftArrow = require("../../assets/Home/CompressedWebP/circlebuttonleft.webp");
  const rightArrow = require("../../assets/Home/CompressedWebP/circlebuttonright.webp");

  
  const CustomArrow: React.FC<{ direction: "left" | "right"; onClick: () => void }> = ({ direction, onClick }) => {
    const [hovered, setHovered] = React.useState(false);
  
    const hoverColor = hovered ? "#ACE0EF" : "#EBE9E6";
    const pathColor = hovered ? "#0C3D4C" : "#1C1B1A";
  
    return (
      <button
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          position: "absolute",
          [direction === "left" ? "left" : "right"]: "10px",
          top: "30%",
          transform: "translateY(-50%)",
          zIndex: 1,
          background: "none",
          border: "none",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: direction === "left" ? "rotate(180deg)" : "none",
            transition: "fill 0.3s ease",
          }}
        >
          <rect width="48" height="48" rx="24" fill={hoverColor} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2929 34.7071C21.9024 34.3166 21.9024 33.6834 22.2929 33.2929L31.5858 24L22.2929 14.7071C21.9024 14.3166 21.9024 13.6834 22.2929 13.2929C22.6834 12.9024 23.3166 12.9024 23.7071 13.2929L33.7071 23.2929C34.0976 23.6834 34.0976 24.3166 33.7071 24.7071L23.7071 34.7071C23.3166 35.0976 22.6834 35.0976 22.2929 34.7071Z"
            fill={pathColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 24C34 24.5523 33.5523 25 33 25L15 25C14.4477 25 14 24.5523 14 24C14 23.4477 14.4477 23 15 23L33 23C33.5523 23 34 23.4477 34 24Z"
            fill={pathColor}
          />
        </svg>
      </button>
    );
  };
  
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: phone ? "fit-content" : "30rem",
        height: "fit-content",
        display: disappear && phone ? "none" : "block",
        margin: phone ? "1rem" : "0",
        padding: phone ? "1rem" : "2rem",
        backgroundColor: "white",
      }}
    >

      <h2 style={{

        display: "block",
        marginBottom: "2rem",
        fontSize: phone? "24px": tablet?"28px":"36px",
        color: '#115C72',
      }}>
        Why <span style={{color:"#795ec6" ,fontStyle:"italic"}}>1500+ families trust us</span>  for senior home care
      </h2>
      <Box
        sx={{
          width: phone ? "100%" : tablet ? "100%" : "1000px",
          margin: "0 auto",
        }}
      >
<Carousel
  autoPlay
  infiniteLoop
  showThumbs={false}
  showIndicators={false}
  showStatus={false}
  renderArrowPrev={(clickHandler) => (
    <CustomArrow direction="left" onClick={clickHandler} />
  )}
  renderArrowNext={(clickHandler) => (
    <CustomArrow direction="right" onClick={clickHandler} />
  )}
>
  {[
    {
      image: first,
      text: `“I am thrilled to know that Herewith is now available to seniors in my area. The idea that they can arrange their own caregivers using an app on their phones is genius. I love that they do not have to sign a long contract and they are able to negotiate the hourly fee they pay.”`,
      author: "Emily",
    },
    {
      image: second,
      text: `“I appreciate that I can call Support when I have a question. It’s important for me to be able to reach people beyond the app when needed. Good customer service.”`,
      author: "George",
    },
    {
      image: third,
      text: `“Through Herewith I found wonderful care for my hundred-year-old Italian mother... She’s always on time, upbeat, and as a CNA, a very capable and compassionate caregiver.”`,
      author: "Dolores",
    },
    {
      image: fourth,
      text: `“I have found the Herewith app to be quite user-friendly, even for someone like me who is not at all tech-savvy. It feels good to have their support. I highly recommend their service. If I had stars to light up, there would be five of them.”`,
      author: "Diane",
    },
    {
      image: fifth,
      text: `“Having that freedom of knowing my mom is in good hands with her caregiver is everything. Herewith gave that to me.”`,
      author: "Suzy",
    },
    {
      image: sixth,
      text: `“I just want to say a big thank you to Herewith. It’s been phenomenal for me. I even have a friend who wants to use it to take care of her mom.”`,
      author: "Pauline",
    },
  ].map(({ image, text, author }, index) => (
    <div key={index} style={{ minHeight: phone ? "8rem" : 0 }}>
      <img
        src={image}
        loading="lazy"
        alt={`Testimonial ${index + 1}`}
        style={{
          margin: "1rem 0 2rem 0",
          borderRadius: "1rem",
          height: "104px",
          width: "104px",
          filter: "drop-shadow(0px 20px 19px #55524E3D)",
        }}
      />
      {author && (
        <div
          style={{
            fontSize: "20px",
            color: "#706D67",
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          {author}
        </div>
      )}
      <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />
      <p className="testimonial-text" 
                style={{
                fontSize: phone? "16px": tablet?"22px":"22px",
                lineHeight: "1.5",
                marginBottom: phone ? "1rem" : "2.5rem",
                color:"#0C3D4C",
              }}>
                {text}
              </p>
    </div>
  ))}
</Carousel>

      </Box>
    </Box>
  );
};

export default NewTestimonials;
