import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import icon1 from "../../assets/icons/Trusted.svg";
import icon2 from "../../assets/icons/Affordable.svg";
import icon3 from "../../assets/icons/Flexible.svg";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";



const bulletPoints = [
  {
    icon: icon1, // Path to SVG icon
    title: "Trusted",
    description: "Every Helper is fully background-checked and covered by liability insurance",
  },
  {
    icon: icon2, // Path to PNG icon
    title: "Affordable",
    description: "Book Helpers directly and pay up to 50% less than care agencies, with no hourly minimums",
  },
  {
    icon: icon3, // Path to PNG icon
    title: "Flexible",
    description: "Pay for as much or as little as you need with part-time, full-time, and one-time help",
  },
];

const LeftContent: React.FC = () => {
    const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height:" 100%",
        gap: desktop?"":"1.5rem",
      }}
    >
      {/* Headline */}
      <h2
  style={{
    fontSize: phone? "24px": tablet?"28px":"36px",
    marginTop:phone?"16px":"",
  }}
>
      Our approach to <br />
        <span
          style={{
            fontStyle: "italic",
            color: "#1D99BE",
          }}
        >
        stress-free senior help 
        </span>{" "}
      </h2>

      {/* Bullet Points */}
      <List>
        {bulletPoints.map((point, index) => (
          <React.Fragment key={index}>
            <ListItem >
              <ListItemAvatar>
                <img
                  src={point.icon} // Use the icon path directly here
                  alt={point.title}
                  style={{
                    width: 40,
                    height: 40,
                    // marginRight: "2rem",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                  className:"testimonial-author",
                }}
                secondaryTypographyProps={{
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  textAlign: "left",
                }}
                primary={point.title}
                secondary={point.description}
              />
            </ListItem>
            {index < bulletPoints.length - 1 && <Divider style={{}} />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default LeftContent;
