import * as React from "react";
import SlideButton from "./SlideButton";
import { styled, alpha } from "@mui/material/styles";
import CircleIcon from '@mui/icons-material/Circle';

import Box from "@mui/material/Box";

import InputBase from "@mui/material/InputBase";
import { Link } from "react-router-dom";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";
import { relative } from "path";
import { Height } from "@mui/icons-material";

interface HeaderProps{
    headerTextBeforeSpan: string;
    headerTextSpan: string;
    headerTextAfterSpan: string;
    paragraphText: string;
    textColor: string;
    headerImage: string;
    backgroundColor: string;
    secondaryBackgroundColor: string;
    buttonText: string;
}

const RHeader: React.FC<HeaderProps> = ({headerTextAfterSpan, headerTextSpan, headerTextBeforeSpan, textColor, headerImage, backgroundColor, secondaryBackgroundColor, buttonText }) => {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const headerimg = require("../assets/Home/CompressedWebP/BAM.webp");
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])
  
  return (
    <div
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        margin: "2rem auto",
        width: "90%",
        height: phone ? "534px" : tablet ? "428px": "576px",
        borderRadius: "2.5rem",
        backgroundColor: backgroundColor,
        overflow: "hidden",
        position: "relative", // Make the parent container relative
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: phone ? "column" : "row",
          alignItems: phone ? "center" : "flex-start",
          justifyContent: "space-around",
          height: "inherit",
          margin: "2rem 0px",
          width: "100%",
          position: "relative", // Ensure text is positioned relative to this container
          zIndex: "1", // Place the content above the icon
        }}
      >
        <Box sx={{ maxWidth: phone ? "92%": "60%", textAlign: phone ? "center" :"left", marginLeft: phone ? "0" : tablet ? "48px": "64px", display: "flex", flexDirection: "column" , justifyContent: "space-between"}}>
        <Box>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <h1 style={{ fontSize: phone ? "28px" : tablet ? "40px" : "3.5rem", width: phone ? "100%" : tablet? "150%": "130%", paddingTop: phone? "0px":"24px"}}>
              {headerTextBeforeSpan} <span style={{ fontStyle: "italic" }}>{headerTextSpan}</span> {headerTextAfterSpan}
            </h1>
            </Link>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                height: "3.5rem",
                marginTop: "2rem",
                borderRadius: 9999,
                fontSize: "18px",
                "&:hover": {
                  background: "#FECA5B",
                },
              }}
            >
              Find a Helper
            </Button>
          </Link>
        </Box>
        <a href="/helpers">
          <Button
            variant="text"
            endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
            sx={{
              textTransform: "none",
              fontSize: phone ? "16px" : tablet ? "16px": "1.25rem",
              color: "#177A98",
              borderBottom: "1px solid #177A98",
              borderRadius: "0",
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: phone ? "2rem" : tablet ? "60px" : "40px",
              width: "fit-content",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#0C3D4C",
                borderColor: "#0C3D4C",
              },
            }}
          >
            Become a Helper instead
          </Button>
        </a>
        </Box>
        <svg style={{display: phone? "none" : tablet? "block": desktop? "block" : "block", marginRight: tablet ? "-68%" : "-65%"}} width={tablet ? "1100" : "1700"} height={tablet ? "750" : "1100"} viewBox="0 0 440 370" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="220" cy="220.5" rx="220" ry="220.5" fill="#DBF7FF"/>
        </svg>

        <svg style={{display: phone? "block" : tablet? "none": desktop? "none" : "none", marginBottom: "-200px"}} width="600" height="200" viewBox="0 0 328 130" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="164" cy="220.5" rx="220" ry="220.5" fill="#DBF7FF"/>
        </svg>

        {/*<CircleIcon sx={{ zIndex: "0", color: secondaryBackgroundColor , marginBottom:"-200px", width: "160vw !important", height: "100vw"}}/>*/}
        <img src={headerimg} 
        style={{
          WebkitFilter: "drop-shadow(-70px 80px 40px RGB(120, 119, 119, 0.5))",
          filter: "drop-shadow(-70px 80px 40px RGB(120, 119, 119, 0.5))",
          alignSelf: phone? "center":"flex-end",
          alignContent: "end",
          width: phone ? "85%" :"50vw",
          maxWidth: "800px",
          marginBottom: phone ? "0": "30px",
          marginLeft: "0px",
          marginTop: "-20%",
          marginRight: phone? "0px" :"-60px",
          zIndex: "1",
        }}/>
      </Box>
    </div>
  );
};

export default RHeader;