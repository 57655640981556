import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import HouseIcon from "../../assets/icons/HouseIcon";
import BagIcon from "../../assets/icons/BagIcon";
import CompanionIcon from "../../assets/icons/CompanionIcon";
import RedoIcon from "../../assets/icons/RedoIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";

const steps = [
  "Select master blaster campaign settings",
  "Create an ad group",
  "Create an ad",
];

interface WeCanHelpProps {}

const WeCanHelp: React.FC<WeCanHelpProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          minHeight: "20rem",
          backgroundColor: "white",
          display: phone ? "none" : "flex",
          flexDirection: tablet ? "column" : "row",
          justifyContent: "space-between",
          padding: tablet ? "0 0 80px 0" : "64px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-around",
            textAlign: tablet ? "center" : "left",
            padding: "64px",
            width: tablet ? "100%" : "",
            gap: tablet ? "24px" : "24px",
          }}
        >
          <h1
            style={{
              width: tablet ? "100%" : "80%",
              fontSize: "2.5rem",
              lineHeight: "1.3",
            }}
          >
            We can help with that
          </h1>
          <p
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              maxWidth: tablet ? "100%" : "80%",
              color: "#0C3D4C",
              fontFamily: "SiteBody"
            }}
          >
            Whether it’s for yourself or a loved one, on Herewith you’ll find
            flexible, real-life answers for your day-to-day needs.
          </p>
          <Link to="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3.5rem",
                fontSize: "18px",
                marginTop: "1rem",
                boxShadow: "none",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Get help with tasks
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            padding: tablet ? "" : "0px",
            width: tablet ? "90%" : "60%",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
            gap: "32px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "4vw" }}>
            {/* Item */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                width: tablet ? "450px" : "350px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "32px" }}>
                  <HouseIcon />
                </Box>
                <Box>
                  <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                    Home Help
                  </h3>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#85827D",
                      lineHeight: "24px",
                      maxHeight: "48px"
                    }}
                    component="p"
                    variant="body2"
                  >
                    Light chores, cooking, or pet care
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: "100%" }} variant="fullWidth" />
            </Box>

            {/* Item */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                width: tablet ? "450px" : "350px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "32px" }}>
                  <CompanionIcon />
                </Box>

                <Box>
                  <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                    Companionship
                  </h3>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#85827D",
                      lineHeight: "24px",
                    }}
                    component="p"
                    variant="body2"
                  >
                    Friendly chats, activities, hobbies
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: "100%" }} variant="fullWidth" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "4vw",
              alignItems: "center",
            }}
          >
            {/* Item */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                paddingTop: "32px",
                width: tablet ? "450px" : "350px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "32px" }}>
                  <BagIcon />
                </Box>
                <Box>
                  <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                    Errands
                  </h3>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#85827D",
                      lineHeight: "24px",
                    }}
                    component="p"
                    variant="body2"
                  >
                    Help with shopping or quick trips
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: "100%" }} variant="fullWidth" />
            </Box>

            {/* Item */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                paddingTop: "32px",
                width: tablet ? "450px" : "350px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "32px" }}>
                  <RedoIcon />
                </Box>
                <Box>
                  <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                    Ongoing Help
                  </h3>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#85827D",
                      maxWidth: "350px",
                      lineHeight: "24px",
                    }}
                    component="p"
                    variant="body2"
                  >
                    For a little extra support with everyday tasks, hire your
                    favorite Helper on a regular schedule
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "4vw" }}>
            {/* Item */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                paddingTop: "32px",
                width: tablet ? "450px" : "350px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ minWidth: "32px" }}>
                  <PhoneIcon />
                </Box>
                <Box>
                  <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                    Basic Tech Help
                  </h3>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#85827D",
                      lineHeight: "24px",
                    }}
                    component="p"
                    variant="body2"
                  >
                    Help with TV's, phones, computers
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: tablet ? "450px" : "350px" }}></Box>
          </Box>
        </Box>
      </Box>

      {/* ------------- mobile ------------ */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem ",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            textAlign: "center",
            padding: "1rem 0 2rem 0",
            alignItems: "center",
          }}
        >
          <h1 style={{ width: "100%" }}>
            We can help <br /> with that
          </h1>
          <p
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.5",
              width: "90%",
              margin: "1rem auto 2rem auto",
            }}
          >
            Whether it’s for yourself or a loved one, on Herewith you’ll find
            flexible, real-life answers for your day-to-day needs.
          </p>
          <Link to="https://app.herewith.com/sign-up/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon fontSize="medium" />}
              sx={{
                width: "fit-content",
                height: "4rem",
                fontSize: "1.1rem",
                boxShadow: "none",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Get help with tasks
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ height: "fit-content", width: "100%", padding: "24px 16px", minWidth: "50vw" }}>
              <Box sx={{ minWidth: "32px", paddingBottom: "12px"}}>
                <HouseIcon />
              </Box>
              <Box>
                <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                  Home Help
                </h3>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#85827D",
                    lineHeight: "24px",
                  }}
                  component="p"
                  variant="body2"
                >
                  Light chores, cooking, or pet care
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ opacity: "0.5" }} orientation="vertical" flexItem />
            <Box sx={{ height: "fit-content", width: "100%", padding: "24px 16px", minWidth: "50vw" }}>
              <Box sx={{ minWidth: "32px", paddingBottom: "12px"}}>
                <BagIcon />
              </Box>
              <Box>
                <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                  Errands
                </h3>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#85827D",
                    lineHeight: "24px",
                  }}
                  component="p"
                  variant="body2"
                >
                  Help with shopping or quick trips
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", opacity: "0.5" }}
            variant="fullWidth"
            flexItem
          />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{ height: "fit-content", width: "100%", padding: "24px 16px", minWidth: "50vw" }}
            >
              <Box sx={{ minWidth: "32px", paddingBottom: "12px"}}>
                <PhoneIcon />
              </Box>
              <Box>
                <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                  Tech Help
                </h3>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#85827D",
                    lineHeight: "24px",
                  }}
                  component="p"
                  variant="body2"
                >
                  Help with TV's, phones, computers
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ opacity: "0.5" }} orientation="vertical" flexItem />
            <Box
              sx={{ height: "fit-content", width: "100%", padding: "24px 16px", gap: "24px", minWidth: "50vw"  }}
            >
              <Box sx={{ minWidth: "32px", paddingBottom: "12px"}}>
                <CompanionIcon />
              </Box>
              <Box>
                <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                  Companionship
                </h3>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#85827D",
                    lineHeight: "24px",
                  }}
                  component="p"
                  variant="body2"
                >
                  Friendly chats, activities, hobbies
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", }}>
            <Divider
              sx={{ width: "100%", opacity: "0.5" }}
              variant="fullWidth"
              flexItem
            />
            <Box sx={{ height: "fit-content", width: "100%", padding: "24px 12px", gap: "24px" }}>
              <Box sx={{ minWidth: "32px", paddingBottom: "12px"}}>
                <RedoIcon />
              </Box>
              <Box>
                <h3 style={{ fontSize: "16px", color: "#0C3D4C" }}>
                  Ongoing Help
                </h3>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#85827D",
                    lineHeight: "24px",
                  }}
                  component="p"
                  variant="body2"
                >
                  For a little extra support with everyday tasks, hire your favorite Helper on a regular schedule
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          opacity: "0.5",
          width: "100%",
        }}
      />
    </>
  );
};

export default WeCanHelp;
