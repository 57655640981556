
import * as React from "react";
import { Box, Button, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import desktopImage from "../assets/About/CompressedWebP/LadiesShopping-16x9.jpeg"
import mobileImage from "../assets/About/CompressedWebP/LadiesShopping-9x16.jpeg"
import curve from "../assets/mapbottom.png"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface AlignedCardProps {}

const AlignedCard: React.FC<AlignedCardProps> = ({}) => {


  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
    <Helmet>
  <meta
    name="description"
    content="Affordable in-home senior care services by Herewith. Find trusted Helpers for errands, meal preparation, companionship, and more. Flexible, affordable, and background-checked care for seniors."
  />
</Helmet>

<Box
  sx={{
    width: "100%",
    height: phone ? "50rem": tablet? "42rem":"45rem",
    minHeight: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: phone ? "center" : tablet ? "flex-start" : "space-between",
    alignItems: phone ? "flex-end" : "center",
    backgroundImage: `url(${phone ? mobileImage : desktopImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: phone ? 0 : tablet ? "3rem" : "4rem",
    position: "relative", // Ensure the decorative image aligns to this container
  }}
>
  <Paper
    sx={{
      width: phone ? "90%" : tablet ? "50%" : "50%",
      minHeight: "fit-content",
      padding: phone ? "2rem 1rem" : tablet ? "40px" : "4rem",
      marginBottom: phone ? "2rem" : "4rem",
      borderRadius: phone ? "2rem" : "2rem",
      textAlign: phone ? "center" : "left",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    }}
  >
    {/* Content inside the Paper */}
    <h2
      style={{
        fontSize: phone? "24px": tablet?"28px":"36px",
        marginBottom: phone ? "10px" : tablet?"1rem":"",
        color: "#115C72",
      }}
    >
      Helping our older loved ones
      <i style={{ color: "#6AA08F" }}> thrive at home</i>
    </h2>
    <p
      style={{
        marginBottom: "2rem",
        fontSize: "1rem",
        lineHeight: 1.5,
        textAlign: phone ? "center" : "left",
        fontFamily: "DM Sans",
        color: "#0C3D4C",
      }}
    >
      Caring for our parents at home is the best option for most families. But
      many of us are flying solo—juggling work, kids, and finances can get
      overwhelming. At Herewith you can find affordable Helpers who offer real
      life solutions, all on your budget and your schedule, bringing peace of
      mind that your loved ones can get the care they deserve.
    </p>
    <a
      href="https://www.youtube.com/watch?v=VE8RNlvJzd0"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
  variant="contained"
  endIcon={
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "transform 0.3s ease", // Smooth transition for the icon movement
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "transform 0.3s ease", // Smooth transition for the icon itself
        }}
      >
        <g id="ICON">
          <path
            id="2"
            d="M14.5 7.13435C15.1667 7.51925 15.1667 8.4815 14.5 8.8664L5.5 14.0626C4.83333 14.4475 4 13.9663 4 13.1965L4 2.80423C4 2.03443 4.83333 1.5533 5.5 1.9382L14.5 7.13435Z"
            fill="black"
          />
        </g>
      </svg>
    </div>
  }
  sx={{
    height: "3.5rem",
    transition: "transform 0.3s ease, background 0.3s ease",
    fontSize: "16px", // Set font size to 16px
    letterSpacing: "0",  // Set line-height to 0 for no spacing between lines
    "&:hover": {
      background: "#E59100",
      transform: "translateX(5px)", // Moves the entire button to the right
      width: "auto", // Optional: Adjust button width if needed
    },
    "&:hover .MuiSvgIcon-root": {
      transform: "translateX(5px)", // Moves the arrow to the right
    },
  }}
>
Watch Suzy’s story
</Button>

    </a>
  </Paper>
  <img
    src={curve}
    alt="Decorative bottom"
    style={{
        display: phone? "none":"",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "auto",
      zIndex: 1,
    }}
  />
</Box>
</>
  );
};

export default AlignedCard;
