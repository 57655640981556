import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import HomepageVideo from "./HomePageVideo";

interface BookTrustedHelpersProps {
  headerText?: string;
  subtext1?: any;
  subtext2?: any,

}

const BookTrustedHelpers: React.FC<BookTrustedHelpersProps> = ({headerText, subtext1, subtext2}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo3&utm_id=marketing"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3rem 5rem",
          minHeight: "25rem",
          backgroundColor: "#FAF8F5",
          // margin: "1rem 0 2rem 0",
          width: "100%",
          borderBottom: "1px solid #EBE9E6",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            textAlign: "left",
            width: "55%",
            minHeight: "inherit !important",
            marginLeft: "3rem",
          }}
        >
          <h1 style={{ width: "80%" }}>
            Book trusted, local{" "}
            <span style={{ fontStyle: "italic", color: "#C97B99" }}>
              Helpers
            </span>{" "}
          </h1>
          <p
            style={{
              margin: ".25rem 0rem 1.5rem 0rem",
              textAlign: "left",
              width: "80%",
              fontSize: "1rem",
              lineHeight: 1.5,
            }}
          >
            {subtext1}
            <br />
            <br />
            {subtext2}
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "40%",
                height: "3rem",
                fontSize: "18px",
                minWidth: "fit-content",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              See Helpers near you
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: "50%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "5rem",
          }}
        >
          {/* <HomepageVideo
            style={{ width: "80%", borderRadius: "3rem" }}
          /> */}
          {/* <img src={image} alt="slider" style={{ width: "80%" }} /> */}
        </Box>
      </Box>

      {/* --------------- tablet --------------- */}

      <Box
        sx={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3rem",
          minHeight: "25rem",
          backgroundColor: "#FAF8F5",
          // margin: "1rem 0 2rem 0",
          width: "100%",
          borderBottom: "1px solid #EBE9E6        ",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            textAlign: "left",
            width: "55%",
            minHeight: "inherit !important",
            marginLeft: "3rem 0",
          }}
        >
          <h1 style={{ width: "75%", fontSize: "2rem" }}>
            Book trusted, local
            <span style={{ fontStyle: "italic", color: "#C97B99" }}>
              {" "}
              Helpers
            </span>
          </h1>
          <p
            style={{
              margin: ".25rem 0rem 1.5rem 0rem",
              textAlign: "left",
              width: "90%",
              fontSize: "1rem",
              lineHeight: 1.5,
            }}
          >
            {subtext1}
            <br />
            <br />
            {subtext2}
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "40%",
                height: "3rem",
                fontSize: "16px",
                minWidth: "fit-content",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              See Helpers near you
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: "50%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "0",
          }}
        >
          {/* <HomepageVideo
            style={{ width: "100%", borderRadius: "3rem" }}
          /> */}
          {/* <img src={image} alt="slider" style={{ width: "80%" }} /> */}
        </Box>
      </Box>

      {/* --------------- mobile --------------- */}
      <Box
        sx={{
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

          minHeight: "25rem",
          backgroundColor: "#FAF8F5",
          padding: "1rem ",
          width: "100%",
          borderBottom: "1px solid #EBE9E6",
          paddingBottom: "2rem",
        }}
      >
        {/* <Box
          sx={{
            display: phone ? "flex" : "none",
            position: "relative",
            left: 0,
            right: 0,
            backgroundImage: `url(${image})`,
            margin: "0 auto",
            width: "90vw",
            height: "55vh",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "1rem",
          }}
        ></Box> */}
        {/* <HomepageVideo
          style={{ width: "100%", borderRadius: "1rem" }}
        /> */}
        <h1 style={{ margin: "2rem 0 1rem 0" }}>
          Book trusted, local{" "}
          <span style={{ fontStyle: "italic", color: "#C97B99" }}>Helpers</span>{" "}
        </h1>
        <p
          style={{
            margin: "0 0rem 1.5rem 0rem",
            textAlign: "center",
            width: "90%",
            fontSize: "1.1rem",
            lineHeight: 1.5,
          }}
        >
          {subtext1}
          <br />
          <br />
          {subtext2}
        </p>
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
            sx={{
              width: "90%",
              minWidth: "fit-content",
              height: "4rem",
              fontSize: "1.1rem",
              "&:hover": {
                background: "#FECA5B",
                // Add other hover styles as needed
              },
            }}
          >
            See Helpers near you
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default BookTrustedHelpers;
