import React from "react";
import Box from "@mui/material/Box";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Its_a_B from "../../assets/icons/Its_a_B.svg"
import { tab } from "@testing-library/user-event/dist/tab";


const AcordianLeft: React.FC = () => {
    const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        height:" 100%",
        gap: "1.5rem",
        // padding:desktop?0:"1rem",
        // paddingLeft: phone ? "" : tablet ? "1rem": "4rem",
        // paddingRight: desktop? 0:"4rem",
      }}
    >
      {/* Headline */}
      <h2 style={{ fontSize: phone? "24px": tablet?"28px":"36px",}}>
      Our  
        <span
          style={{
            fontStyle: "italic",
            color: "#FF9540",
          }}
        >
         {" "}senior care services 
        </span>{" "}
        help with everyday tasks
      </h2>   
      <p style={{color:"#0C3D4C",paddingRight:desktop?0: "1rem",paddingBottom:phone?"2rem":"" }}>
      Book senior care tailored to your family’s needs. Share exactly what kind of help you need, when you need it, and how much you want to pay. 
      </p>
      <div style={{display:phone? "none": ""}}>
      <Link to={`https://app.herewith.com/sign-up${utm}`}>
      <Button
            variant="contained"
            endIcon={
              <ArrowForwardIcon
                sx={{
                  fontSize: "1.5rem",
                  transition: "transform 0.3s ease", // Smooth transition for the movement
                }}
              />
            }
            sx={{
              height: "3.5rem",
              transition: "transform 0.3s ease, background 0.3s ease",
              fontSize: "16px", // Set font size to 16px
              letterSpacing: "0",  // Set line-height to 0 for no spacing between lines
              "&:hover": {
                background: "#E59100",
                transform: "translateX(5px)", // Moves the entire button to the right
                width: "auto", // Optional: Adjust button width if needed
              },
              "&:hover .MuiSvgIcon-root": {
                transform: "translateX(5px)", // Moves the arrow to the right
              },
            }}
          >
            Request a Helper today
          </Button>
          </Link>
      </div>
      <div style={{ position: "relative", display: phone ? "none" : "block" }}>
  {/* Enlarged ITS_A_B SVG positioned absolutely */}
  <img
    src={Its_a_B}
    alt="ITS_A_B"
    style={{
      position: "absolute",
      top: "-50px", // Move the SVG up by 50px
      left: "-50px", // Adjust to position the SVG horizontally
      width: 600, // Enlarged size
      height: 600, // Enlarged size
      zIndex: -1, // Place behind the button
    }}
    
  />
</div>
    </Box>
  );
};

export default AcordianLeft;
