import React, { useState, useEffect } from "react";
import { Box, Button, useMediaQuery, useTheme, createTheme } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import icon1 from "../../assets/icons/Truck.svg";
import icon2 from "../../assets/icons/Spray.svg";
import icon4 from "../../assets/icons/Burger.svg";
import icon5 from "../../assets/icons/Ladder.svg";
import icon6 from "../../assets/icons/Cute.svg";
import icon7 from "../../assets/icons/Phone.svg";

// Define items outside of the component for better performance
const items = [
  { icon: icon1, title: "Help with errands and getting to appointments", content: "Helpers can help you get to appointments, wait for you, and then accompany you home to make sure everything goes smoothly. Or do errands for you, like picking up a prescription or groceries." },
  { icon: icon2, title: "Help at home with light cleaning and tidying", content: "Assign your Helper specific tasks, like sorting the mail, cleaning out the fridge, folding laundry, and making sure the floor is clear of tripping hazards." },
  { icon: icon4, title: "Cooking and meal preparation", content: "Helpers can cook hot meals, assist with weekly food prep, or just help out around the kitchen, making sure food is fresh and accessible." },
  { icon: icon5, title: "Mobility assistance and medication reminders", content: "Helpers can provide non-medical support for seniors and adults, whether it’s respite care, temporary help after a health event, or ongoing care." },
  { icon: icon6, title: "Companionship and social activities", content: "Helpers can be a friendly companion that comes around to check on you or your loved one, and will always provide companionship in addition to other tasks." },
  { icon: icon7, title: "Help using a smartphone or computer", content: "Helpers can provide basic support with your devices, like setting up video calls, downloading an app, or even just putting batteries in the TV remote." },
];

interface CustomIconProps {
  style?: React.CSSProperties;
  fillColorRect?: string;
  fillColorPath?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({ style, fillColorRect = "#EBE9E6", fillColorPath = "#85827D" }) => (

   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect width="32" height="32" rx="16" fill={fillColorRect} />
    <path
      d="M12.868 15.7406L15.3376 18.6909C15.6822 19.1026 16.3174 19.1026 16.6621 18.6909L19.1317 15.7406C19.5996 15.1816 19.2004 14.333 18.4695 14.333L13.5302 14.333C12.7993 14.333 12.4001 15.1816 12.868 15.7406Z"
      fill={fillColorPath}
    />
  </svg>
);

const AccordionItem: React.FC<{ index: number; item: any; expanded: number[]; onToggle: (index: number) => void; hoveredIndex: number | null; onHover: (index: number) => void; onLeave: () => void }> = ({
  index,
  item,
  expanded,
  onToggle,
  hoveredIndex,
  onHover,
  onLeave,
}) => (
  
  <div style={{ marginBottom: "16px", borderRadius: "16px", overflow: "hidden" }}>
<div
  onClick={() => onToggle(index)}
  onMouseEnter={() => onHover(index)}
  onMouseLeave={onLeave}
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "20px",
    backgroundColor: hoveredIndex === index ? "#DBF7FF" : "#FAF8F5",
    borderRadius: "16px",
    transition: "background-color 0.3s ease",
    flexWrap: "wrap", // Allow for wrapping on small screens
  }}
>
  <h3 style={{ display: "flex", alignItems: "center",justifyContent: "flex-start", textAlign: "left", fontSize: "16px", color: "#0C3D4C", flex: "1", fontWeight:"100",gap:"20px"}}>
    <img src={item.icon} alt="Item Icon" style={{ width: 40, height: 40, marginRight: "10px" }} />
    {item.title}
  </h3>
  <CustomIcon
    style={{
      transform: expanded.includes(index) ? "rotate(180deg)" : "rotate(0)",
      transition: "transform 0.3s",
      flex: "0 0 auto",  // Prevent icon from shrinking
      width: "30px",  // Consistent width
      height: "30px",  // Consistent height
    }}
    fillColorRect={hoveredIndex === index ? "#ACE0EF" : "#EBE9E6"}
    fillColorPath={hoveredIndex === index ? "#0C3D4C" : "#85827D"}
  />
</div>

    {expanded.includes(index) && (
      <p style={{ padding: "20px", textAlign: "justify",  color: "#706D67", fontSize: "16px" }}>
        {item.content}
      </p>
    )}
  </div>
);

const AccordianRight: React.FC = () => {
  const [expanded, setExpanded] = useState<number | null>(null); // Track only one expanded item
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpanded(expanded === index ? null : index); // Toggle or close other items
  };

  const onHover = (index: number) => setHoveredIndex(index);
  const onLeave = () => setHoveredIndex(null);

  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1200,
        xl: 760,
      },
    },
  });

  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const [utm, setUTM] = useState("/?utm_campaign=facility_launch_24&utm_source=family_home&utm_medium=page&utm_content=cta&utm_id=website");

  useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        {items.map((item, index) => (
          <AccordionItem
            key={index}
            index={index}
            item={item}
            expanded={expanded === index ? [index] : []} // Only pass the current expanded index
            onToggle={handleToggle}
            hoveredIndex={hoveredIndex}
            onHover={onHover}
            onLeave={onLeave}
          />
        ))}
      </div>

      {phone && (
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
          <Button
            variant="contained"
            endIcon={
              <ArrowForwardIcon
                sx={{
                  fontSize: "1.5rem",
                  transition: "transform 0.3s ease",
                }}
              />
            }
            sx={{
              height: "3.5rem",
              marginTop: phone ? "1rem" : "3rem",
              transition: "transform 0.3s ease, background 0.3s ease",
              fontSize: "16px",
              letterSpacing: "0",
              "&:hover": {
                background: "#E59100",
                transform: "translateX(5px)",
              },
              "&:hover .MuiSvgIcon-root": {
                transform: "translateX(5px)",
              },
            }}
          >
            Request a Helper today
          </Button>
        </Link>
      )}
    </Box>
  );
};


export default AccordianRight;
