import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import ArrowButton from "../../components/ArrowButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import HomepageVideo from "../../components/HomePageVideo";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

interface EverydayReliefProps {
  img: string;
  mobileImg: string;
  title1: string;
  title2: string;
  text: string;
  cta: string;
  // color of second line of the header
  subColor: string;
}

const EverydayRelief: React.FC<EverydayReliefProps> = ({
  img,
  title1,
  title2,
  cta,
  subColor,
  mobileImg,
  text,
}) => {
  const [dense, setDense] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: phone || tablet ? "100%" : "75%",
        height: phone ? "fit-content" : "38rem",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: phone ? "column" : "row",
        justifyContent: "space-between",
        margin: phone ? "0 auto" : "3rem auto",
        paddingBottom: phone ? "2rem" : 0,
      }}
    >
      {/* image */}

      
      {/* <HomepageVideo

        style={{
          height: phone ? "55vh" : tablet ? "26rem" : "35rem",
          borderRadius: phone ? "none" : "3rem",
          aspectRatio: "1",

          margin: phone || tablet ? "1rem" : 0,
        }}
      /> */}

      {/* text */}

      <Box
        sx={{
          width: phone ? "100%" : "50%",

          padding: phone ? "0 1.5rem" : tablet ? "2rem" : "5rem",
          textAlign: phone ? "center" : "left",
          // height: "90%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: tablet ? "flex-start" : "center",
        }}
      >
        <h1
          style={{
            fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            marginLeft: phone ? "1rem" : 0,
          }}
        >
          {title1}
          <span
            style={{
              fontStyle: "italic",
              color: subColor,
              fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            }}
          >
            {" "}
            {title2}
          </span>
        </h1>

        <p
          style={{
            margin: "1.5rem 0 2rem 0",
            color: "#0C3D4C",
            lineHeight: 1.5,
          }}
        >
          {text}
        </p>
        <ArrowButton text={cta} link="/partners#getintouch" />
      </Box>
    </Box>
  );
};

export default EverydayRelief;
